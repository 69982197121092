import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app'
import "firebase/messaging"
import "firebase/functions"
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n'


import { SettingsProvider } from './SettingsProvider';


ReactDOM.render((
    <SettingsProvider>
      <App />
    </SettingsProvider>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
