import React, {useState} from 'react'
import { Hidden, Grid, Button, makeStyles,  AppBar, Toolbar, IconButton, Drawer, Typography, Grow, Slide } from '@material-ui/core';
import { useTheme, createStyles, Theme } from '@material-ui/core/styles';
import useWindowSize from '../utils/useWindowSize';
import HideOnScroll from './HideOnScroll';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import ChatIcon from '@material-ui/icons/Chat';
import { sendWhatsApp } from '../utils/sendWhatsApp';
import { sendFaceBook } from '../utils/sendFaceBook';
import Instagram from './Instagram';
import {sendEvent, EventCategory, EventAction} from '../ga'
import { sendMailHref } from '../utils/mails';


const useHeader = makeStyles((theme: Theme) =>
  createStyles({
    header_mobile: {
      position: "fixed",
      bottom: 0,
      left: 0,
      backgroundColor: "transparent",
      transition: "0.5s",
      display: "block",
      fontFamily: "'Open Sans', 'sans-serif'",
      width: (props: { width: number }) => `100vw`,
      fontWeight: 300,
      fontSize: "15px",
      lineHeight: 1.6,
      zIndex: 1000


    },
    appBar: {
      flexGrow: 1, 
      height: "64px", 
      zIndex: 1300,
      background: "rgba(25,250,0,0.8)",
      borderTopLeftRadius: "30px",
      borderTopRightRadius: "30px",
      "&:before": {
        content: "''",

      }
    },
    a: {
      textDecoration: "none",
      color: "black",
    }


  }))




const MHeader = () => {
  const [width, ] = useWindowSize()
  const headerClasses = useHeader({ width })
  const [isHidden, setHidden] = useState(false)

  const onHidden = (isHidden: boolean) => {
    setHidden(isHidden)
  }

  const handleWhatsAppClick = () => {
    const title = "Hallo Elma"
    const text ="Ich bin an den ELMA Isotank interessiert. Könnten Sie mir mehr Informationen zusenden?"
    sendEvent(EventCategory.CONTACT, EventAction.WHATSAPP, "ContactFooter")
    sendWhatsApp({title, text})

  }
  const handleFacebookClick = () => {
    const title = "Hallo Elma"
    const text ="Ich bin an den ELMA Isotank interessiert. Könnten Sie mir mehr Informationen zusenden?"
    sendEvent(EventCategory.CONTACT, EventAction.FACEBOOK, "ContactFooter")
    sendFaceBook({title, text})
  }

  const sendFaceBookMessenger = () => {
    sendEvent(EventCategory.SOCIAL, EventAction.FACEBOOK, "ContactFooter")
    window.open("https://www.messenger.com/t/ELMA.Isotank", "_blank")

  }


  const handleCall = () => {
    sendEvent(EventCategory.SOCIAL, EventAction.CALL, "ContactFooter")
    window.open("tel:00496202924012", "_self")
  }

  const handleMail = () => {
    sendEvent(EventCategory.SOCIAL, EventAction.EMAIL, "ContactFooter")
    sendMailHref({email: "info@elma-isotank.de", body: "Ich bin an den ELMA Isotank interessiert. Könnten Sie mir mehr Informationen zusenden?", subject: "Kontaktanfrage"})
  }


 

  

  return (
      <Hidden lgUp>
        <div style={{ flexGrow: 1, }} className={headerClasses.header_mobile}>
        <HideOnScroll direction={"up"} onHidden={onHidden} >
    <AppBar className={headerClasses.appBar} position="static">
  <Toolbar>
    <Grid container wrap="nowrap" style={{flexGrow: 1}}  justify="space-around">
      <Grid item>
      <IconButton  aria-label="call">
  <CallIcon onClick={handleCall} style={{fill: "black", fontSize :"32px"}} />
</IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={handleMail}>
       <MailOutlineIcon  style={{fill: "black", fontSize :"32px"}}/>
        </IconButton>
      </Grid>
      <Grid item>
      <IconButton  onClick={handleWhatsAppClick} aria-label="call">
  <WhatsAppIcon  style={{fill: "black", fontSize :"32px"}} />
</IconButton>
      </Grid>
      
<Grid item>
<IconButton  aria-label="insta">
<Instagram location={'ContactFooter'}  height={32}/>
</IconButton>
</Grid>
      <Grid item>
        
<IconButton  aria-label="call">
<i onClick={sendFaceBookMessenger} className="fab fa-facebook-messenger" style={{ color: "blue", fontSize: "32px"}}/>
</IconButton>

      </Grid>
      {false &&
      <Grid item>
      <IconButton onClick={handleMail} aria-label="call">
  <ChatIcon style={{fontSize :"32px"}} />
</IconButton>
      </Grid>
}
    </Grid>
  </Toolbar>
</AppBar>
</HideOnScroll>
        </div>
      </Hidden>
  )
}


export default MHeader