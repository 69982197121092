import React, { useEffect, Suspense } from 'react'
import { CssBaseline, createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { useSettingsContext } from './SettingsProvider'
import { useTranslation } from 'react-i18next'
import useWindowSize from './utils/useWindowSize'
import AppRouter from './Router'
import CookieConstentDialog from './Terms/CookieConsentDialog'
import {Helmet} from "react-helmet";



function App() {
  const { i18n } = useTranslation()
  const { state: { darkmode, locale } } = useSettingsContext()
  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        type: 'light',
        primary: {
          main: '#64BE43'
        },
        background: {
          default: '#f2f2f2',
          paper: '#303030'
        },
      
      },
      overrides: {
        MuiButton: {
          // Name of the rule
          root: {
            // Some CSS
            borderRadius: '3em',
          },
        },
      }
    }),
    [darkmode],
  )

  const [, height] = useWindowSize()


  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale, i18n])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" data-react-helmet="true" content="Frostsicher im Winter. Kühl & Frisch im  Sommer. Ob Offenstall, Weide oder Stall. Kein Strom und Wasseranschluss nötig"/>
            <title data-react-helmet="true">Die frostsichere Pferdetränke für Weide und Stall | ELMA-Isotank </title>
            
        </Helmet>
      <div style={{
        textAlign: 'center',
        alignContent: 'center',
        backgroundColor: theme.palette.background.default,
        minHeight: height - 18 + 'px',
        padding: 'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
      }}>
        
      <AppRouter />
      </div>
      <CookieConstentDialog />
    </ThemeProvider>
  )
}

const AppWithSuspense = () => (
  <Suspense fallback={null}>
    <App />
  </Suspense>
)

export default AppWithSuspense